import Rules from "@/mock/drawer-form-submit-rules"
export default {
  data () {
    return {
      searchForm: {},
      buttonData: [],
      table: {
        params: {},
        total: 0,
        data: [],
        operates: []
      },
      page: {
        pageSize: 20,
        pageNum: 1
      },
      rules: Rules[this.$route.name]
    }
  },
  created () {
    this.getOperation()
  },
  methods: {
    submitForm (form) {
      this.searchForm = form
      this.page = { pageNum: 1, pageSize: 20 }
      this.getTableData()
    },
    resetForm () {
      this.searchForm = {}
      this.page = { pageNum: 1, pageSize: 20 }
      this.getTableData()
    },
    handleParams () {
      const params = JSON.parse(JSON.stringify(this.searchForm))
      for (const key in params) {
        if (key === "time") {
          params.startTime = params.time && params.time.length > 0 ? params.time[0] : undefined
          params.endTime = params.time && params.time.length > 0 ? params.time[1] : undefined
          this.$delete(params, "time")
        }
      }

      this.table.params = params
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.getTableData()
    },
    handleCurrentChange (val) {
      this.page.pageNum = val
      this.getTableData()
    },

    getOperation () {
      const operates = {
        buttonData: [],
        cellData: []
      }

      // const menu = JSON.parse(localStorage.getItem("menuRoutes"))
      const menu = this.$store.state.user.menuList
      menu.map(m => {
        if (m.children) {
          m.children.map(child => {
            if ("#" + child.resourceUrl === window.location.hash && child.children) {
              child.children.map(c => {
                if (c.isCell === 1) {
                  operates.cellData.push({
                    name: c.resourceName,
                    prop: c.resourceUrl.replace("/", ""),
                    pageUrl: localStorage.getItem("defaultActive") // 添加菜单的页面路径pageUrl，区分不同页面按钮
                  })
                } else if (c.isCell === 0) {
                  operates.buttonData.push(c.resourceName)
                }
              })
            }
          })
        }
      })
      this.table.operates = operates.cellData
      console.log("ListMix-operate", this.table)
      this.buttonData = operates.buttonData
    }
  }
}
