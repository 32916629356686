import Request from "@/api/normalService"

/**
 * 获取建筑用电量排行
 * @param {object} params
 * @param {string} params.timeType - 统计时间维度 1 - 月，2 - 年
 * @param {string} params.top - 排行数量
 * @returns
 */
export function getElectricityUseRankApi (params) {
  return Request({
    url: "/base/statistics/building/ep/top",
    method: "get",
    params
  })
}

/**
 * 获取设备状态统计
 * @returns
 */
export function getDeviceDataApi () {
  return Request({
    url: "/base/statistics/device/state/statistics",
    method: "get"
  })
}

/**
 * 获取用电统计
 * @param {object} params
 * @param {string} params.timeType - 统计时间维度 1 - 月，2 - 年
 * @returns
 */
export function getElectricityUseApi (params) {
  return Request({
    url: "/base/statistics/ep/statistics",
    method: "get",
    params
  })
}

/**
 * 获取充值退费统计
 * @returns
 */
export function getPayStatisticsApi () {
  return Request({
    url: "/pay/recharge/payStatistics",
    method: "get"
  })
}

/**
 * 获取快捷菜单
 * @returns
 */
export function getQuickMenuDataApi () {
  return Request({
    unique: true,
    url: "/user/resource/quickResource",
    method: "get"
  })
}

/**
 * 发布快捷菜单
 * @returns
 */
export function updateQuickMenuDataApi (data) {
  return Request({
    unique: true,
    url: "/user/resource/quickResource",
    method: "post",
    data
  })
}

/**
 * 发布快捷菜单
 * @returns
 */
export function getUserMenuApi () {
  return Request({
    unique: true,
    url: "/user/resource/current/user",
    method: "get"
  })
}

