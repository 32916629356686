const PayManagement = [
  { prop: "buildingName", label: "建筑名称", width: "160" },
  { prop: "tenantInfo", label: "租户信息", width: "" },
  { prop: "deviceNumber", label: "电表号", width: "120" },
  { prop: "comState", label: "通讯状态", width: "" },
  { prop: "brakeControl", label: "闸控状态", width: "" },
  { prop: "balance", label: "余额(元)", width: "" },
  { prop: "lastRechargeTime", label: "最近缴费时间", width: "140" },
  { prop: "accountState", label: "充值二维码", width: "100" }
]

// 退费管理
const RefundManagement = [
  { prop: "orderNumber", label: "退费流水号", width: "160" },
  { prop: "buildingName", label: "建筑名称", width: "160", page: "buildingFullName", showOverflowTooltip: true },
  { prop: "tenantInfo", label: "租客信息", width: "" },
  { prop: "deviceName", label: "电表号", width: "" },
  { prop: "recordCreateTime", label: "退费发起时间", width: "160" },
  { prop: "estimateAmount", label: "预计退费金额", width: "160" },
  { prop: "refundAmount", label: "退费金额", width: "" },
  { prop: "content", label: "备注", width: "" },
  { prop: "recordState", label: "处理状态", width: "100" },
  { prop: "recordUpdateTime", label: "最近操作时间", width: "160" }
]

const GateControl = [
  { prop: "buildingName", label: "建筑名称", width: "" },
  { prop: "tenantInfo", label: "租户信息", width: "" },
  { prop: "deviceName", label: "电表号", width: "160" },
  { prop: "balance", label: "余额(元)", width: "120" },
  { prop: "comState", label: "通讯状态", width: "" },
  { prop: "brakeControl", label: "闸控状态", width: "" },
  { prop: "continuous", label: "是否保电", width: "" },
  { prop: "lastComTime", label: "最后通讯时间", width: "160" },
  { prop: "ep", label: "当前电表度数（kWh)", width: "160" },
  { prop: "updateUser", label: "操作人", width: "" },
  { prop: "updateTime", label: "最近操作时间", width: "160" }
]

const PowerProtection = [
  { prop: "buildingName", label: "建筑名称", width: "" },
  { prop: "tenantInfo", label: "租户信息", width: "" },
  { prop: "deviceName", label: "电表号", width: "160" },
  { prop: "balance", label: "余额（元）", width: "120" },
  { prop: "comState", label: "通讯状态", width: "" },
  { prop: "brakeControl", label: "闸控状态", width: "" },
  { prop: "continuous", label: "是否保电", width: "" },
  { prop: "lastComTime", label: "最后通讯时间", width: "160" },
  { prop: "ep", label: "当前电表度数（kWh)", width: "160" },
  { prop: "updateUser", label: "操作人", width: "" },
  { prop: "updateTime", label: "最近操作时间", width: "160" }
]

// 抄读记录
const ReadingRecord = [
  { prop: "deviceNumber", label: "电表号", width: "160" },
  { prop: "buildingName", label: "建筑名称", width: "160" },
  { prop: "comAddress", label: "通讯地址", width: "160" },
  { prop: "time", label: "抄读时间", width: "160" },
  { prop: "balance", label: "余额（元）", width: "160" },
  { prop: "totalUseEp", label: "日用量（kWh）", width: "160" },
  { prop: "ep", label: "总用电量(kWh)", width: "160" },
  { prop: "rate1Ep", label: "尖用量(kWh)", width: "160" },
  { prop: "rate2Ep", label: "峰用量(kWh)", width: "160" },
  { prop: "rate3Ep", label: "平用量(kWh)", width: "160" },
  { prop: "rate4Ep", label: "谷用量(kWh)", width: "160" }
]

// 异常事件
const AbnormalEvent = [
  { prop: "buildingName", label: "建筑名称", width: "200", page: "buildingFullName", showOverflowTooltip: true },
  { prop: "tenantInfo", label: "租户信息", width: "200" },
  { prop: "deviceNumber", label: "电表号", width: "" },
  { prop: "alarmLevel", label: "告警等级", width: "" },
  { prop: "eventContent", label: "告警内容", width: "160" },
  { prop: "happenTime", label: "发生时间", width: "160" }
]

const AlarmRules = [
  { prop: "eventName", label: "告警规则名称", width: "160" },
  { prop: "alarmLevelName", label: "等级", width: "" },
  { prop: "eventDesc", label: "规则描述", width: "" },
  { prop: "deviceEventTypeName", label: "大类", width: "" },
  { prop: "isRelatedDevice", label: "是否关联对象", width: "160" },
  { prop: "pushRuleName", label: "推送规则", width: "" }
]

const PushRules = [
  { prop: "pushRuleName", label: "推送规则名称", width: "160" },
  { prop: "relatedStateName", label: "关联状态", width: "" },
  { prop: "comment", label: "备注", width: "" },
  { prop: "updateUserName", label: "操作人", width: "" },
  { prop: "updateTime", label: "最近操作时间", width: "160" }
]

const CostRules = [
  { prop: "eventName", label: "费控规则名称", width: "160" },
  { prop: "ruleTypeName", label: "规则类型", width: "" },
  { prop: "eventDesc", label: "规则描述", width: "" },
  { prop: "buildingName", label: "关联区域", width: "" }
]

// 短信催缴记录
const ShortMessageRecord = [
  { prop: "buildingName", label: "建筑名称", width: "", page: "buildingFullName", showOverflowTooltip: true },
  { prop: "tenantInfo", label: "租户信息", width: "" },
  { prop: "deviceNumber", label: "电表号", width: "" },
  { prop: "createTime", label: "催缴时间", width: "160" }
]

// 抄读结算报表
const ReadingSettlementReport = [
  { prop: "buildingName", label: "建筑名称", width: "200", page: "buildingFullName", showOverflowTooltip: true },
  { prop: "userInfo", label: "租客信息", width: "160" },
  { prop: "deviceNumber", label: "电表号", width: "160" },
  { prop: "balance", label: "剩余金额(元)", width: "160" },
  { prop: "totalEp", label: "抄读示数(kWh)", width: "160" },
  { prop: "totalUseEp", label: "总用电量(kWh)", width: "160" },
  { prop: "rate1UseEp", label: "尖用量(kWh)", width: "160" },
  { prop: "rate2UseEp", label: "峰用量(kWh)", width: "160" },
  { prop: "rate3UseEp", label: "平用量(kWh)", width: "160" },
  { prop: "rate4UseEp", label: "谷用量(kWh)", width: "160" },
  { prop: "totalUseFare", label: "费用(元)", width: "160" },
  { prop: "energyPriceDetail", label: "电价信息", width: "160" },
  { prop: "dataTime", label: "统计时间", width: "240" }
]

const EnergyAnalysis = [
  { prop: "dataTime", label: "统计时间", width: "160" },
  { prop: "useEp", label: "用电量(kW)", width: "160" },
  { prop: "maxPower", label: "最大功率(kWh)", width: "160" },
  { prop: "maxPowerTime", label: "最大功率发生时间", width: "160" },
  { prop: "minPower", label: "最小功率(kWh)", width: "160" },
  { prop: "minPowerTime", label: "最小功率发生时间", width: "160" }
]

// 缴费统计
const PaymentStatistics = [
  { prop: "statisticsTime", label: "统计时间", width: "160" },
  { prop: "chargerAmount", label: "充值金额(元)", width: "160" },
  { prop: "refundAmount", label: "退费金额(元)", width: "160" },
  { prop: "transactionCount", label: "交易笔数(笔)", width: "160" },
  { prop: "paidInAmount", label: "实缴金额(元)", width: "160" },
  { prop: "paidInRelativeRatio", label: "实缴环比(%)", width: "160" },
  { prop: "paidInYearRatio", label: "实缴同比(%)", width: "160" }
]

// 费用明细
const FeeSchedule = [
  { prop: "transactionPortStr", label: "端口", width: "" },
  { prop: "operatorName", label: "操作人", width: "" },
  { prop: "transactionCount", label: "交易笔数（笔）", width: "160" },
  { prop: "amount", label: "金额(元)", width: "" },
  { prop: "serviceCharge", label: "手续费(%)", width: "160" },
  { prop: "arrivalAmount", label: "到账金额(元)", width: "160" }
]

// 缴费分类汇总
const PaymentClassification = [
  { prop: "payWayStr", label: "付款方式", width: "" },
  { prop: "operatorName", label: "操作人", width: "" },
  { prop: "deviceTypeStr", label: "电表类型", width: "" },
  { prop: "operateTypeStr", label: "操作类型", width: "" },
  { prop: "transactionCount", label: "缴费笔数", width: "" },
  { prop: "amount", label: "缴费金额", width: "" }
]

const BuildManagement = [
  { prop: "fullName", label: "姓名", width: "" },
  { prop: "gender", label: "性别", width: "" },
  { prop: "phoneNum", label: "手机号", width: "" },
  { prop: "createTime", label: "添加时间", width: "" }
  // { prop: "comment", label: "备注", width: "" }
]

// 电表管理
const ElectricityMeter = [
  { prop: "deviceNumber", label: "电表号", width: "", fieldCode: "deviceNumber" },
  { prop: "deviceTypeName", label: "电表类型", width: "", fieldCode: "deviceTypeName" },
  { prop: "energyType", label: "能源类型", width: "", fieldCode: "energyType" },
  { prop: "comAddress", label: "通讯地址", width: "", fieldCode: "comAddress" },
  { prop: "ratio", label: "CT变比", width: "", fieldCode: "ratio" },
  { prop: "comTypeNameList", label: "通讯类型", width: "", fieldCode: "comTypeNameList" },
  { prop: "initAmount", label: "预留金额", width: "", fieldCode: "initAmount" },
  { prop: "buildingName", label: "建筑信息", width: "", fieldCode: "buildingName" },
  { prop: "price", label: "电价", width: "", fieldCode: "price" },
  { prop: "eventRule", label: "费控规则", width: "", fieldCode: "eventRule" },
  { prop: "comment", label: "备注", width: "", fieldCode: "comment" },
  { prop: "userName", label: "操作人", width: "", fieldCode: "userName" },
  { prop: "updateTime", label: "最近操作时间", width: "160", fieldCode: "updateTime" },
  { prop: "qrCode", label: "充值二维码", width: "160", fieldCode: "qrCode" }
]

const ElectricityPrice = [
  { prop: "energyPriceName", label: "收费方案名称", width: "160" },
  { prop: "energyType", label: "能源类型", width: "" },
  { prop: "price", label: "方案详情", width: "200" },
  { prop: "updateUserName", label: "操作人", width: "" },
  { prop: "updateTime", label: "最近操作时间", width: "160" }
]

// 电价方案下发记录
const ElectricityPriceDistribution = [
  { prop: "buildingName", label: "建筑名称", width: "160" },
  { prop: "deviceNumber", label: "电表号", width: "120" }, // prop待确认
  { prop: "price", label: "电价详情", width: "" }, // prop待确认
  { prop: "fullName", label: "操作人", width: "" },
  { prop: "createTime", label: "任务创建时间", width: "160" },
  { prop: "effectiveStartTime", label: "任务完成时间", width: "160" },
  { prop: "planEffectiveTime", label: "电价生效时间", width: "160" },
  { prop: "energyPriceStatus", label: "任务状态", width: "" }
]

const TenantManagement = [
  { prop: "userName", label: "手机号", width: "" },
  { prop: "fullName", label: "姓名", width: "" },
  { prop: "gender", label: "性别", width: "" },
  { prop: "buildingName", label: "租用建筑", width: "" },
  { prop: "createUserName", label: "添加人", width: "" },
  { prop: "createTime", label: "添加时间", width: "" }
]

const RateTimeConfig = [
  { prop: "name", label: "时段名称", width: "" },
  { prop: "timeFrameList1", label: "尖时段", width: "100" },
  { prop: "timeFrameList2", label: "峰时段", width: "100" },
  { prop: "timeFrameList3", label: "平时段", width: "100" },
  { prop: "timeFrameList4", label: "谷时段", width: "100" },
  { prop: "operatorName", label: "操作人", width: "" },
  { prop: "operateTime", label: "最近操作时间", width: "120" }
]

const PeriodDistribution = [
  { prop: "deviceNumber", label: "电表号", width: "120" },
  { prop: "timeFrameName", label: "时段详情", width: "130", showOverflowTooltip: true },
  { prop: "operatorName", label: "操作人", width: "" },
  { prop: "taskCreateTime", label: "任务创建时间", width: "160" },
  { prop: "taskFinishTime", label: "任务完成时间", width: "160" },
  { prop: "effectiveTime", label: "时段生效时间", width: "160" },
  { prop: "taskStateStr", label: "任务状态", width: "" }
]
const RoleManagement = [
  { prop: "roleName", label: "角色名", width: "" },
  { prop: "roleDesc", label: "描述", width: "" }
]

const UserManagement = [
  { prop: "userName", label: "用户名", width: "" },
  { prop: "phoneNum", label: "手机号", width: "" },
  { prop: "fullName", label: "姓名", width: "" },
  { prop: "roleName", label: "角色", width: "" },
  { prop: "status", label: "状态", width: "" },
  { prop: "comment", label: "备注", width: "" }
]
// 操作日志
const OptLog = [
  { prop: "operateTimeStr", label: "操作时间", width: "" },
  { prop: "operatorUsername", label: "用户名", width: "" },
  { prop: "operatorFullName", label: "操作人姓名", width: "" },
  { prop: "platformStr", label: "平台", width: "" },
  { prop: "menu", label: "操作菜单", width: "" },
  { prop: "optType", label: "操作类型", width: "" },
  { prop: "operatorContent", label: "操作内容", width: "" },
  { prop: "ip", label: "IP地址+浏览器", width: "120" }
]

// 充值退费记录
const PayRefundRecord = [
  { prop: "transactionNumber", label: "充值/退费编号", width: "160" },
  { prop: "time", label: "充值/退费时间", width: "160" },
  { prop: "buildingName", label: "建筑名称", width: "160" },
  { prop: "deviceNumber", label: "电表号", width: "160" },
  { prop: "tenantInfo", label: "租户信息", width: "" },
  { prop: "operateTypeName", label: "操作类型", width: "" },
  { prop: "payWayName", label: "付款方式", width: "" },
  { prop: "transactionStateName", label: "状态", width: "120" },
  { prop: "estimateAmount", label: "预计充退金额(元）", width: "150" },
  { prop: "amount", label: "金额（元）", width: "120" },
  { prop: "userName", label: "操作用户", width: "120" },
  { prop: "userTel", label: "用户手机号", width: "120" }
]

// 充值卡管理
const RechargeCard = [
  { prop: "orderNumber", label: "充值编号", width: "180" },
  { prop: "deviceNumber", label: "电表号", width: "140" },
  { prop: "platformStr", label: "平台", width: "80" },
  { prop: "payWayStr", label: "付款方式", width: "" },
  { prop: "payAmount", label: "金额(元）", width: "100" },
  { prop: "usageStateStr", label: "使用状态", width: "120" },
  { prop: "userName", label: "办卡用户", width: "100" },
  { prop: "cardProcessingTime", label: "办卡时间", width: "180" },
  { prop: "useTime", label: "使用时间", width: "180" },
]
// 日冻结数据
const DailyFreezeData = [
  { prop: "deviceNumber", label: "电表号", width: "160" },
  { prop: "buildingName", label: "建筑名称", width: "180" },
  { prop: "comAddress", label: "通讯地址", width: "160" },
  { prop: "freezeTime", label: "冻结时间", width: "180" },
  { prop: "ep", label: "总用量(kWh)", width: "160" },
  { prop: "rate1Ep", label: "尖用量(kWh)", width: "160" },
  { prop: "rate2Ep", label: "峰用量(kWh)", width: "160" },
  { prop: "rate3Ep", label: "平用量(kWh)", width: "160" },
  { prop: "rate4Ep", label: "谷用量(kWh)", width: "160" },
]

export default {
  PayManagement,
  RefundManagement,
  GateControl,
  PowerProtection,
  ReadingRecord,
  AbnormalEvent,
  AlarmRules,
  PushRules,
  CostRules,
  ShortMessageRecord,
  ReadingSettlementReport,
  EnergyAnalysis,
  PaymentStatistics,
  FeeSchedule,
  PaymentClassification,
  BuildManagement,
  ElectricityMeter,
  ElectricityPrice,
  ElectricityPriceDistribution,
  TenantManagement,
  RateTimeConfig,
  PeriodDistribution,
  RoleManagement,
  UserManagement,
  PayRefundRecord,
  OptLog,
  RechargeCard,
  DailyFreezeData,
}
