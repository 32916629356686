<template>
  <JyPageContentLayout page-title="充值卡管理">
    <template #header>
      <jy-forms :form="searchForm" @submit="submitForm" @reset="resetForm" />
    </template>

    <template #body>
      <div class="table-container">
        <div class="button">
          <el-button v-if="buttonData.includes('导出')" plain class="button_authority_white" @click="handleExport">
            <SvgIcon icon-class="ElectricityUpload" width="14px" />
            导出
          </el-button>
        </div>
      </div>
      <jy-table :loading="tableLoading" :data="table.data" :operates="table.operates" :total="table.total" :page-num="page.pageNum" @used="handleUsed" @undo="handleUndo" :operatesWidth="120"
        :page-size="page.pageSize" :tableRowClass="tableRowClass"
        @changeSize="handleSizeChange" @changeNum="handleCurrentChange" />
    </template>
  </JyPageContentLayout>
</template>

<script>
import ListMix from "@/mixins/list"
import { exportFiles } from "@/utils";
export default {
  mixins: [ListMix],
  data() {
    return {
      tableLoading: true,
    }
  },
  created() {
    this.getTableData()
  },
  methods: {
    async getTableData () {
      this.tableLoading = true
      this.handleParams()
      const params = {
        ...this.page,
        ...this.table.params
      }
      try {
        const res = await this.$http.get(this.$api.RechargeCard.tableList, params)
        this.table.data = res.list || []
        this.table.total = res.count
      } catch(error) {

      } finally {
        this.tableLoading = false
      }
    },
    handleExport() {
      const params = {
        ...this.page,
        ...this.table.params
      }
      return exportFiles(
        this.$api.RechargeCard.exportList,
        "充值卡管理",
        params
      )
    },
    handleUsed(row) {
      this.$confirm("已确认充值在线下已被使用，确认将充值卡状态变更为【已使用】？", "操作确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      }).then(async () => {
        try {
          await this.$http.post(this.$api.RechargeCard.cardUsed(row.id))
          this.$message.success("操作成功！")
          this.getTableData()
        } catch (err) {
          this.$message.error(err.message)
        }
      }).catch(() => {

      })
    },
    handleUndo(row) {
      this.$confirm(`确定将充值订单${row.orderNumber}进行红冲吗？`, "红冲", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      }).then(async () => {
        try {
           await this.$http.post(this.$api.RechargeCard.undo(row.id))
          this.$message.success("操作成功！")
          this.getTableData()
        } catch (err) {
          this.$message.error(err.message)
        }
      }).catch(() => {

      })
    },
    tableRowClass({ row, rowIndex }) {
      if (row.cardType === 2) {
        return 'error-row-class'
      } else {
        return ''
      }
    }
  }
}
</script>
