export const DeviceType = [{
  label: "NB-单相表",
  value: 1
}, {
  label: "NB-三相表",
  value: 2
}, {
  label: "NB-三相互感",
  value: 3
}, {
  label: "4G-单相表",
  value: 4
}, {
  label: "4G-三相表",
  value: 5
}, {
  label: "4G-三相互感",
  value: 6
}]

export const ComTypeList = [{
  label: "NB",
  value: 1
}, {
  label: "红外线",
  value: 2
}, {
  label: "485",
  value: 3
}, {
  label: "蓝牙",
  value: 4
}, {
  label: "4G",
  value: 5
}]

export const PriceType = [{
  label: "单一电价",
  value: 1
}, {
  label: "复费率电价",
  value: 2
}, {
  label: "阶梯电价",
  value: 3
}]

export const EnergyType = [{
  label: "电",
  value: 1
}]

export const BalanceStatus = [{
  label: "已欠费",
  value: 2
}, {
  label: "未欠费",
  value: 3
}]

export const Gender = [
  {
    label: "男",
    value: 1
  },
  {
    label: "女",
    value: 2
  }]

export const Continuous = [
  {
    label: "否",
    value: 0
  },
  {
    label: "是",
    value: 1
  }]

export const RuleType = [{
  label: "费用透支",
  value: 2
}, {
  label: "费用预警",
  value: 1
}]

export const BrakeControl = [{
  label: "合闸",
  value: 0
}, {
  label: "断闸",
  value: 1
}]

export const RecordState = [{
  label: "待处理",
  value: 0
}, {
  label: "待确认",
  value: 1
}, {
  label: "已取消",
  value: 2
}, {
  label: "仲裁中",
  value: 3
}, {
  label: "已完成",
  value: 4
}]

export const StatisticsType = [{
  label: "按日",
  value: 0
}, {
  label: "按月",
  value: 1
}]

export const EnergyPriceStatus = [{
  label: "下发中",
  value: 1
}, {
  label: "已完成",
  value: 2
}, {
  label: "下发失败",
  value: 3
}]

export const IsRelated = [{
  label: "已关联",
  value: 1
},
{
  label: "未关联",
  value: 0
}]

export const AlarmLevel = [
  {
    label: "预警",
    value: 1
  },
  {
    label: "告警",
    value: 2
  },
  {
    label: "故障",
    value: 3
  }
]

export const IsRelatedDevice = [
  {
    label: "否",
    value: 0
  },
  {
    label: "是",
    value: 1
  }
]

export const RechargeType = [
  {
    label: "充值",
    value: 0
  },
  {
    label: "退费",
    value: 1
  }
]

// 付款方式
export const PAY_WAY = [
  {
    value: 1,
    label: "转账"
  }, {
    value: 2,
    label: "汇款"
  }, {
    value: 3,
    label: "现金"
  }, {
    value: 4,
    label: "免费补费"
  }
]

// 缴费分类汇总-付款方式
export const PAY_WAY2 = [
  {
    value: 1,
    label: "转账"
  }, {
    value: 2,
    label: "汇款"
  }, {
    value: 3,
    label: "现金"
  }, {
    value: 5,
    label: "微信"
  }
]

// 充值类型
export const OPERATION_TYPE = [
  {
    value: 1,
    label: "在线充值"
  }, {
    value: 2,
    label: "在线退费"
  }, {
    value: 3,
    label: "微信充值"
  }, {
    value: 4,
    label: "微信退费"
  }
]

// 充值卡平台
export const RECHARGE_CARD_PALTFORM = [
  {
    value: 1,
    label: "租客端"
  }, {
    value: 2,
    label: "管理端"
  }
]

// 充值卡付款方式
export const RECHAGRE_CARD_PAY_WAY = [
  {
    value: 1,
    label: "转账"
  }, {
    value: 2,
    label: "汇款"
  }, {
    value: 3,
    label: "现金"
  }, {
    value: 4,
    label: "免费补费"
  }, {
    value: 5,
    label: "微信"
  }, {
    value: 6,
    label: "出厂配置"
  }
]


// 充值卡使用状态
export const RECHARGE_CARD_USE_STATE = [{
  value: 0,
  label: "待使用"
}, {
  value: 1,
  label: "已使用"
},]
