import RechargeCard from "@/views/3-cost/RechargeCard.vue"

export default {
  index: "/user/resource/current/user",
  passwordCheck: (password) => { return `/oauth/user/password?password=${password}` },
  UserManagement: {
    userList: "/user/user",
    userState: (userId) => { return `/user/user/${userId}` },
    phoneCheck: (phoneNum) => { return `/user/user/check/landlord/${phoneNum}` },
    levelInfo: "/user/role/level"
  },
  RoleManagement: {
    roleList: "/user/role",
    roleName: "/user/role/name",
    roleResource: (roleId) => { return `/user/role/resource/${roleId}` },
    roleInfo: (roleId) => { return `/user/web/systemManagement/roleAuthorityManagement/getRole/${roleId}` },
    roleNameQuery: "/user/role/checkRoleName",
    buildingList: "/base/building/role/building",
    getNewResource: "/user/web/system-management/role-authority-management/tree-resource"
  },
  BuildManagement: {
    buildingList: "/base/building/", // 后面修改：拥有数据权限的建筑列表
    baseInfo: (buildingId) => { return `/base/web/archivesManagement/buildingManagement/baseInfo/${buildingId}` },
    energyPriceInfo: (buildingId) => { return `/base/energyPrice/building/${buildingId}` },
    deviceInfo: (buildingId) => { return `/base/device/building/${buildingId}` },
    // tenantInfo: (buildingId) => { return `/base/businessRelationshipRel/listByBuildingId/${buildingId}` },
    tenantInfo: "/base/businessRelationshipRel/listByBuildingId",

    detailInfo: (buildingId) => { return `/base/web/archivesManagement/buildingManagement/edit/detail/${buildingId}` },
    childrenBuildingInfo: (buildingId) => { return `/base/building/subset/${buildingId}` },

    parentBuilding: (buildingId) => { return `/base/building/superior/${buildingId}` },
    allPrice: "/base/energyPrice/all",
    energyPriceHistory: (buildingId) => { return `/base/energyPriceHistory/building/${buildingId}` },
    availableDevice: "/base/device/available",
    tenantList: "/base/businessRelationshipRel/listByCompany",
    submitEditInfo: "/base/building/",

    delBuilding: (buildingId) => { return `/base/building/${buildingId}` },
    canDelBuilding: (buildingId) => { return `/base/building/isBuildingDeleted/${buildingId}` }
  },
  ElectricityMeter: {
    deviceList: "/base/web/archivesManagement/meterManagement/pageDevice",
    IsDeviceExist: (deviceNumber) => { return `/base/web/archivesManagement/meterManagement/checkDeviceNumber?deviceNumber=${deviceNumber}` },
    deviceListConfirm: "/base/web/archivesManagement/meterManagement/createDevice",
    deviceListUpdate: "/base/web/archivesManagement/meterManagement/updateDevice",
    viewInfo: (deviceId) => { return `/base/device/${deviceId}` },
    deviceAdd: "/base/device/add",
    deviceEdit: (deviceId) => { return `/base/device/edit/${deviceId}` },
    deviceCheck: (deviceId) => { return `/base/web/archivesManagement/meterManagement/getDevice/?id=${deviceId}` },
    buildList: "/base/building/device",
    buildId: (id) => { return `/base/web/archivesManagement/meterManagement/getUpdateDevice/?id=${id}` },
    buildInfo: (buildingId) => { return `/base/building/hierarchy/${buildingId}` },
    priceInfo: (buildingId) => { return `/base/energyPrice/building/${buildingId}` },
    exportInfo: "/base/web/archivesManagement/meterManagement/exportExcel",
    exportbatch: "/base/web/archivesManagement/meterManagement/downloadImportExcelTemplate",
    exportError: (importRecordId) => { return `/base/web/archivesManagement/meterManagement/downloadErrorImportExcelFile?importRecordId=${importRecordId}` },
    // 自定义列表
    getCustomTableList: "/base/web/archivesManagement/meterManagement/customTableHeader",
    postCustomTableList: "/base/web/archivesManagement/meterManagement/updateCustomTableHeader"
  },
  ElectricityPrice: {
    priceList: "/base/energyPrice",
    addPrice: "/base/web/archivesManagement/powerPriceManagement/create", // 新增电价
    editPrice: "/base/web/archivesManagement/powerPriceManagement/update", // 编辑电价
    priceInfo: (energyPriceId) => { return `/base/energyPrice/detail/${energyPriceId}` },
    buildingName: (energyPriceId) => { return `/base/building/energyPrice/${energyPriceId}` },
    buildingList: "/base/building/energyPrice/available",
    buildingIdList: (energyPriceId) => { return `/base/building/energyPrice/${energyPriceId}` },
    priceInfoDel: (energyPriceId) => { return `/base/energyPrice/${energyPriceId}` },
    exportInfo: "/base/energyPrice/excel"
  },
  ElectricityPriceDistribution: {
    tableList: "/base/energyPriceHistory/",
    exportInfo: "/base/energyPriceHistory/excel",
    sendInfo: (energyPriceHistoryRelId) => { return `/base/energyPriceHistory/${energyPriceHistoryRelId}` }
  },
  PayManagement: {
    tableList: "/base/web/cosManagement/rechargeManger/pageBuildingRecharge",
    rechargeRecord: "/pay/recharge/listBuildingRechargeRecord",
    shortMessage: "/pay/recharge/smsRemind",
    onlineRechargeList: "/pay/recharge/pageOnlineRechargeRecord",
    onlineBuildingList: "/base/energyPrice/available",
    buildingBalance: "/pay/recharge/getOnlineRechargeBuildingBalance",
    onlineRechargeInfo: "/pay/recharge/getOnlineRechargeInfo",
    downloadRechargeInfo: "/pay/recharge/downloadPayRechargeVoucher",
    getOnlineRechargeInfo: "/pay/web/costManager/rechargeManager/recharge/getOnlineRechargeInfo",
    getOnlineRefundInfo: "/pay/web/costManager/rechargeManager/recharge/getOnlineRefundInfo",
    confirmRecharge: "/pay/web/costManager/rechargeManager/recharge/confirmRecharge",
    confirmRefund: "/pay/web/costManager/rechargeManager/recharge/confirmRefund"
  },
  RefundManagement: {
    tableList: "/pay/refund/pageRefundManage",
    checkInfo: (refundRecordId) => { return `/pay/refund/${refundRecordId}` },
    refundInfo: "/pay/refund/uploadVoucher"
  },
  PayRefundManagement: {
    tableList: "/pay/web/costManager/rechargeAndTransaction/pageRechargeAndTransaction",
    manualRefund: "/base/web/costManager/rechargeAndRefund/manualRefund",
    checkRefund: (id) => { return `/base/web/costManager/rechargeAndRefund/getManualRefund/${id}` },
  },
  // 抄读结算
  ReadingSettlementReport: {
    tableList: "/base/statistics/fare",
    exportInfo: "/base/web/statement/readDataStatistics/exportExcel"
  },
  EnergyAnalysis: {
    tableList: "/base/statistics/useEp",
    exportInfo: "/base/statistics/useEp/exportExcel"
  },
  RateTimeConfig: {
    addConfig: "/base/web/archivesManagement/multiRateTimeFrameConfig/create",
    getConfigList: "/base/web/archivesManagement/multiRateTimeFrameConfig/page",
    editConfig: "/base/web/archivesManagement/multiRateTimeFrameConfig/update",
    deleteConfig: (id) => { return `/base/web/archivesManagement/multiRateTimeFrameConfig/delete/${id}` },
    getConfigEditDetail: (id) => { return `/base/web/archivesManagement/multiRateTimeFrameConfig/updateInfo/${id}` },
    getConfigDetail: (id) => { return `/base/web/archivesManagement/multiRateTimeFrameConfig/getById/${id}` },
    periodDistribute: "/base/web/archivesManagement/multiRateTimeFrameConfig/issueTimeFrame",
    getDeviceList: "/base/web/archivesManagement/multiRateTimeFrameConfig/issueTimeFrame/pageMeter"
  },
  periodDistribution: {
    getList: "/base/web/archivesManagement/timeFrameIssueRecord/page",
    reDistribute: (id) => { return `/base/web/archivesManagement/timeFrameIssueRecord/retryIssue/${id}` },
    exportList: "/base/web/archivesManagement/timeFrameIssueRecord/exportExcel"
  },
  optLog: {
    getList: "/common-log/prepay/web/systemManager/operateLog/pge",
    exportList: "/common-log/prepay/web/systemManager/operateLog/excelExport"
  },
  DailyFreezeData: {
    tableList: '/base/web/operateManagement/dailyFreezeData/page',
    exportList: "/base/web/operateManagement/dailyFreezeData/export"
  },
  RechargeCard: {
    tableList: '/base/web/cosManagement/rechargeCardManger/page',
    exportList: "/base/web/cosManagement/rechargeCardManger/export",
    undo: (id) => { return `/base/web/cosManagement/rechargeCardManger/undo/${id}` },
    cardUsed: (id) => { return `/base/web/cosManagement/rechargeCardManger/used/${id}` },
  },

}
